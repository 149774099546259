<template lang="pug">
.banner-wrap
	span {{ t('payment.creditCard.error') }}
	| &nbsp;
	a.link(:href="`${config.public.SINGA_RESOLVE_PREFIX}/account/billing/?lang=${locale}`") {{ t('general.readMore') }}
</template>

<script setup lang="ts">
const { t } = useI18n()
const config = useRuntimeConfig()
const { locale } = useI18n()
</script>

<style lang="sass" scoped>
.banner-wrap
	text-align: center
	padding: 6px $spacing-8 $spacing-8
	background-color: $color-yellow-40
	z-index: 40
span, a
	color: black
	@include font(basier, medium)
	@include fontSize(xs)
	line-height: 1
	display: inline-block
.link
	text-decoration: underline
	cursor: pointer
</style>
